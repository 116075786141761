//import './core';

// import "../application/general/\core-js-polyfill"
// import 'core-js';

// import "exports-loader?window.jQuery!expose-loader?exposes=window.$!../external/jquery/js/jquery";
// import angular from "angular/angular";
// import "exports-loader?exports=window.angular!angular/angular";
// import "expose-loader?exposes=angular!angular/angular";
// import "expose-loader?exposes=window.angular!angular/angular";
import "angular";

// import "expose-loader?exposes=angular!angular/js/angular";
import "angular-route/angular-route";
import "angular-touch/angular-touch";
import "angular-cookies/angular-cookies";
import "angular-resource/angular-resource";

// import "../external/angular-bootstrap/js/ui-bootstrap-tpls";
import "../external/angular-carousel/js/angular-carousel";
import "../external/angular-cookie/js/angular-cookie";

// import "../external/bootstrap/bootstrap.js";
import "../external/bootstrap/js/affix.js";
import "../external/bootstrap/js/modal.js";
import "expose-loader?exposes=bootbox!../external/bootbox.js/js/bootbox";
//import "jPlayer/js/jquery.jplayer.js";

// ngImago
import "./polyfill/matchMedia-polyfill";
import "../external/ng-imago/js/ng-imago";

// import "mousetrap/mousetrap.js";
// import "angular-hotkeys/src/hotkeys.js";
// import "angular-hotkeys/src/hotkeys.css";

import "angular-sanitize";
import "ng-i18next";

import "recordrtc";